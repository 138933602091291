<template>
  <div class="container">
    <Suspense>
      <Navigator :onSeniorEnd="onSeniorEnd" />
    </Suspense>
    <div class="right-container">
      <Suspense>
        <router-view
          @edit-profile="toggleEdit"
          @change-end-status="changeEndStatus"
          @change-target-chatter="changeTargetChatter"
          :onSeniorEnd="onSeniorEnd"
          :reRender="isEditingProfile"
          :targetChatterId="targetChatterId"
        />
      </Suspense>
    </div>
    <Suspense>
      <EditProfile v-if="isEditingProfile" @end-editing="toggleEdit" />
    </Suspense>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import useLogOut from "@/composables/useLogOut";
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import Navigator from "@/components/Navigator.vue";
import EditProfile from "@/components/Home/editProfile.vue";

export default {
  components: {
    Navigator,
    EditProfile,
  },
  setup() {
    const { user } = getUser();
    const { logOut } = useLogOut();
    const router = useRouter();
    const isEditingProfile = ref(false);
    const onSeniorEnd = ref(false);
    const targetChatterId = ref(null)
    let counter = 0;

    const handleLogOut = async () => {
      await logOut();
      router.push({ name: "Official" });
    };

    const changeTargetChatter = (chatterId) => {
      targetChatterId.value = chatterId;
    };
    
    const toggleEdit = () => {
      isEditingProfile.value = !isEditingProfile.value;
    };

    const changeEndStatus = (flag) => {
      onSeniorEnd.value = flag;
    };

    watchEffect(() => {
      // console.log("onSeniorEnd changed to: " + onSeniorEnd.value);
    });
    // console.log(router.currentRoute.value.name)
    switch (router.currentRoute.value.name) {
      case "Senior-Dashboard":
      case "Senior-PostOppDetail":
      case "Senior-PostOppDetail":
      case "Senior-EditPostOpp":
      case "Senior-PostNewOpp":
      case "Senior-WishList":
      case "Senior-WishCompany":
      case "Senior-WishDetail":
      case "Senior-Messages":
      case "Senior-FAQ":
      case "Senior-AccountSettings":
      case "Report":
        onSeniorEnd.value = true;
        break;
    }

    return {
      user,
      onSeniorEnd,
      handleLogOut,
      isEditingProfile,
      toggleEdit,
      changeEndStatus,
      targetChatterId,
      changeTargetChatter
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.right-container {
  background-color: var(--background);
  height: 100vh;
  flex: 1 1 auto;
  overflow-y:scroll;
}
</style>
