import { db } from '@/firebase/config'

const createNewCompany = async (companyId, careerTrack, companyName) => {
  try {
    await db.collection("Companies").add({
      alumniNum: 0,
      careerTrack: careerTrack.value,
      name: companyName.value
    }).then(docRef => {
      companyId.value = docRef.id;
    })
  } catch (error) {
    alert("createNewCompany:" + error);
  }

};

export default createNewCompany
