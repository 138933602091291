import { ref } from 'vue'
import { auth, eventListeners } from '../firebase/config'
import { clearCache } from './wrapWithCache'

// refs
const error = ref(null)

// logout function
const logOut = async () => {
  error.value = null

  eventListeners.forEach(listener => listener())

  clearCache()

  try {
    await auth.signOut()
  }
  catch(err) {
    // console.log(err.message)
    error.value = err.message
  }
}

const useLogOut = () => {
  return { error, logOut }
}

export default useLogOut