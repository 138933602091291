import { db } from '@/firebase/config'

const getAllCompanies = async (store) => {
  try {
    await db.collection("Companies").get().then(docs => {
      let result = []
      docs.forEach(doc => {
        result.push({ ...doc.data(), id: doc.id })
      });
      result.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    	})
      store.value = result
    })
  } catch (error) {
    alert(error)
  }
};

export default getAllCompanies
