import { db, eventListeners } from '@/firebase/config'

let unsubscribe = null;

const getUnreadMsgs = async (store, uid) => {
  try {
    store.value = [];

    if (unsubscribe) {
      unsubscribe();
      eventListeners.splice(eventListeners.indexOf(unsubscribe), 1);
    }

    unsubscribe = await db.collection("Profiles").doc(uid).collection("myUnreadMsgs").doc("unreadMsgs").onSnapshot(snapshot => {
      try {
        store.value = snapshot.data().unreadMsgs
      }
      catch (error) {
        alert("getUnreadMsgs " + error)
      }
    })
    eventListeners.push(unsubscribe)

  } catch (error) {
    alert("getUnreadMsgs " + error)
  }

};

export default getUnreadMsgs
