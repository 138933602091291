import { db } from '@/firebase/config'
import getUser from "@/composables/getUser";

const { user } = getUser()

const editProfile = async (data, uid) => {
  try {
    // if(data.currentCompany!=null&&data.currentCompany!="default"){
    //   await db.collection("Companies").doc(data.currentCompany).get().then((doc)=>{
        
    //     doc.ref.update({
    //       alumniNum: doc.data().alumniNum - 1
    //     })
    //   })
    // }
    // if(data.company!=null&&data.company!="default"){
    //   await db.collection("Companies").doc(data.company).get().then((doc)=>{
    //     doc.ref.update({
    //       alumniNum: doc.data().alumniNum + 1
    //     })
    //   })
    // }
    await db.collection("Profiles").doc(uid).update({
      about: data.about,
      careerTrack: data.careerTrack,
      major: data.major,
      company: data.company,
      GPA: Number(data.gpa),
      firstName: data.firstName,
      lastName: data.lastName,
      year: data.year,
      experience: data.experience
    })
    await user.value.updateProfile({ displayName: data.firstName + " " + data.lastName })

  } catch (error) {
    alert(error)
  }

};

export default editProfile
